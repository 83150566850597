import Dropbox from '../../components/dropbox/Dropbox';
import { Link, useNavigate } from 'react-router-dom';
import Search from '../../components/search/Search';
import BolList from '../../components/list/BolList';
import React, { useState, useEffect} from 'react';
import { config } from '../../Constants.js';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './form.css';

const unique_id = uuid();
const formId= 'POD-'.concat(unique_id.slice(0,12));

const Form = () => {
    const navigate = useNavigate();
    const [fileName, setFileName] = useState();
    const [bol, setBol] = useState([]);
    const [bolList, setBolList] = useState([]);
    const [search, setSearching] = useState([]);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (!Cookies.get('username')) {
          navigate('/')
        }
      });

    const submit = () => {
        if (!Cookies.get('username')) {
            navigate('/');
        }
        else if(!fileName) {
            alert('Please add file to form.');
        }
        else if (bol.length == 0) {
            alert('Please select BOL.');
        }
        else {
            setDisable(true);
            const data = {
                'fileName' : fileName,
                'bol' : bol,
                'username' : Cookies.get('username'),
                'formId' : formId,
            }

            Axios.post(config.url.SUBMIT, data, {
                headers: { "Content-type": "application/json" }
            }).then((response) => {navigate('/feedback') })
        }
    }

    const logout = () => {
        Cookies.remove('username');
        Cookies.remove('jwt-auth');
        Cookies.remove('carriername');
        navigate('/');
    }

  return (
    <div className="container">
        <div className="formContainer">
            <div className="formTop">
                <div className="topTitle">
                    <h1>POD Form</h1>
                    <h5 className='formId'>Form ID: {formId}</h5>
                </div>
            </div>
            <div className="formMid">
                <div className="formDropbox">
                    <Dropbox setFileName={setFileName} formId={formId}/>
                </div>
                <div className="formSearch">
                    <div className="searchTitle">
                        <h2>BOL Search:</h2>
                    </div>
                    <div className="searchBox">
                        <Search list={bolList} setSearching={setSearching}/>
                    </div>
                </div>
                <div className="formBolList">
                    <div className="bolTitle">
                        <h2><span>* </span>BOL List:</h2>
                        <div className="bolSubTitle">(Select 1)</div>
                    </div>
                    <div className="bolList">
                        <BolList setBol={setBol} setBolList={setBolList} search={search}/>
                    </div>
                </div>
            </div>
            <div className="formBottom">
                <div className="submitButton">
                    <Button id="submit" name="submit" size="medium" variant="contained" disabled={disable} onClick={submit}>Submit</Button>
                </div>
                <div className="logoutButton">
                    <Button size="medium" variant="contained" onClick={logout} >Log&nbsp;out</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Form