import React from 'react'
import './image.css'
import logo from './gtms_logo.png';

const Image = () => {
  return (
    <img src={logo} alt="Logo" />
  )
}

export default Image