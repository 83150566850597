   const prod = {
      url: {
         LOGIN: 'https://gtms-pod-be.azurewebsites.net/login',
         REGISTER: 'https://gtms-pod-be.azurewebsites.net/register',
         VERIFY_TOKEN: 'https://gtms-pod-be.azurewebsites.net/login/verify',
         PO_LIST: 'https://gtms-pod-be.azurewebsites.net/form/polist',
         BOL_LIST: 'https://gtms-pod-be.azurewebsites.net/form/bollist',
         SUBMIT: 'https://gtms-pod-be.azurewebsites.net/form/submit',
         FILE_UPLOAD: 'https://gtms-pod-be.azurewebsites.net/blob/fileupload',
      }
   }

   const dev = {
    url: {
     LOGIN: 'http://localhost:3001/login',
     REGISTER: 'http://localhost:3001/register',
     VERIFY_TOKEN: 'http://localhost:3001/login/verify',
     PO_LIST: 'http://localhost:3001/form/polist',
     BOL_LIST: 'http://localhost:3001/form/bollist',
     SUBMIT: 'http://localhost:3001/form/submit',
     FILE_UPLOAD: 'http://localhost:3001/blob/fileupload',
    }
   };

   export const config = prod;