import {Route,  BrowserRouter, Routes } from "react-router-dom";
import Registration from "./pages/registration/Registration";
import Feedback from "./pages/feedback/feedback";
import Login from "./pages/login/Login";
import Form from "./pages/form/form";
import React from "react";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes path="/">
          <Route index element={<Login/>}/>
          <Route path="form">
            <Route index element={<Form/>}/>
          </Route>
          <Route path="feedback">
            <Route index element={<Feedback/>}/>
          </Route>
          <Route path="registration">
            <Route index element={<Registration/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
