import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Constants.js';
import Button from '@mui/material/Button';
import React, { useState} from 'react';
import './registration.css';
import Axios from 'axios';
import { Select } from '@mui/material';


const Registration = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();

    const Submit = () => {
        Axios.post(config.url.REGISTER, {
            firstName: firstName,
            lastName: lastName,
            username: username,
            password: password,
            email: email,
            company: company
        }).then().catch()
    }

    const Back = () => {
        navigate('/')
    }

  return (
    <div className="regContainer">
      <div className="regFormContainer">
        <div className="regFormTop">
          <div className="regFormTitle">
            <h1>POD Registration</h1>
          </div>
        </div>
        <div className="regFormMid">
            <div className="row">
              <div className="refinputbox ">
                  <div>
                    <TextField id="standard-basic" label="First Name" variant="standard" type="text" onChange={e => setFirstName(e.target.value)}/>
                  </div>
              </div>
              <div className="refinputbox ">
                  <div>
                    <TextField id="standard-basic" label="Last Name" variant="standard" type="text" onChange={e => setLastName(e.target.value)}/>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="refinputbox ">
                  <div>
                    <TextField id="standard-basic" label="Username" variant="standard" type="text" onChange={e => setUsername(e.target.value)}/>
                  </div>
              </div>
              <div className="refinputbox ">
                  <div>
                    <TextField id="standard-basic" label="Password" variant="standard" type="text" onChange={e => setPassword(e.target.value)}/>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="refinputbox ">
                  <div>
                    <TextField id="standard-basic" label="Email" variant="standard" type="text" onChange={e => setEmail(e.target.value)}/>
                  </div>
              </div>
              <div className="refinputbox ">
                  <div>
                    <TextField id="standard-basic" label="Company" variant="standard" type="text" onChange={e => setCompany(e.target.value)}/>
                  </div>
              </div>
          </div>
        </div>
        <div className="regFormbottom">
          <div className="regSubmit">
            <Button variant="contained" onClick={Submit}>Submit</Button>
          </div>
          <div className="regBack">
            <Button variant="contained" onClick={Back}>Back</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration