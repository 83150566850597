import React, {useCallback, useState} from 'react';
import { config } from '../../Constants.js';
import {useDropzone} from 'react-dropzone';
import Cookies from 'js-cookie';
import Axios from 'axios';
import './dropbox.css';

function Dropbox({setFileName, formId}) {

  const onDrop = useCallback((acceptedFiles) => {
    setFile('UPLOADING FILE. . . ')
    acceptedFiles.forEach((file) => {
      const podData = new FormData();
      podData.append('files', file);
      podData.append('username', Cookies.get('username'));
      podData.append('carriername', Cookies.get('carriername'));
      podData.append('formid', formId);

      Axios.post(config.url.FILE_UPLOAD, podData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        setFile(res.data.fileName);
        setFileName(res.data.newName)
      })
    })
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})
  const [file, setFile] = useState();


  return (
    <div {...getRootProps({className: 'dropzone'})}>
      <input {...getInputProps()} />
      <p><span>* </span>Drag & drop file here, or click to select file</p>
      <h4 className='fileHeader'>
          <u>&nbsp;File Name Uploaded:</u>
        </h4>
      <ul className='file'>
          {file}
        </ul>
    </div>
  )
}

export default Dropbox