import { Link, useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import React, { useEffect} from 'react'
import Cookies from 'js-cookie';
import './feedback.css';

const Feedback = () => {
  const navigate = useNavigate();

  const logOut = () => {
    Cookies.remove('username');
    Cookies.remove('jwt-auth');
    navigate('/');
  }

  const form = () => {
    window.location.href="/form"
  }

  return (
    <div className='feedbackContainer'>
      <div className="feedback">
        <div className="top"><CheckIcon style={{ fontSize: 150 }}/></div>
        <div className="mid1"><h1>Thank You!</h1></div>
        <div className="mid2"><h2>Your submission has been sent.</h2></div>
        <div className="bottom">
          <Link to="/form" style={{textDecoration: "none"}}>
            <Button style={{width: '225px'}} size="large" variant="contained" onClick={form}>Submit Another POD</Button>
          </Link>
          <Link to="/" style={{textDecoration: "none"}}>
            <Button size="large" variant="contained" onClick={logOut}>Log&nbsp;Out</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Feedback