import Image from '../../components/image/Image';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Constants.js';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import Cookies from 'js-cookie';
import Axios from 'axios';
import './login.css';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitButton, setSubmitButton] = useState(false);

    const login = () => {
        setSubmitButton(true)
        Axios.post(config.url.LOGIN, {
            username: username,
            password: password,
        }).then((response) => {
            Cookies.set('username', response.data.username);
            Cookies.set('jwt-auth', response.data.accessToken);
            Cookies.set('carriername', response.data.carrierName);
            Axios.post(config.url.VERIFY_TOKEN, {
                token: Cookies.get('jwt-auth'),
            }).then((response) => {

                if (response.data.verified === true) {
                    navigate('/form');
                }
                else {
                    alert("Incorrect username or password");
                    setSubmitButton(false);
                    navigate('/');
                }
            })
        })
    };

  return (
    <div className='loginContainer'>
        <div className='login'>
            <div className='title'>
                <Image/>
                <h1>POD Login</h1>
            </div>
            <div className='username'>
                <TextField id="outlined-basic" label="Username" size="small" variant="outlined" onChange={e => setUsername(e.target.value)} />
            </div>
            <div className='password'>
                <TextField id="outlined-basic" label="Password" type="password" size="small" variant="outlined" onChange={e => setPassword(e.target.value)}/>
            </div>
            <div className='submit'>
                <div className="loginButton">
                    <Button disabled={submitButton} variant="contained" onClick={login}>Log In</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login