import React, { useState, useEffect} from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import { config } from '../../Constants.js';
import List from '@mui/material/List';
import Cookies from 'js-cookie';
import Axios from 'axios';


const BolList = ({setBol, setBolList, search}) => {

  const [checked, setChecked] = useState([]);
  const [bolPost, setBolPost] = useState([]);
  const carrierName = Cookies.get('carriername');
  const userName = Cookies.get('username');

  useEffect(() => {
    if (search.length > 0) {
      setBolPost(search[0]);
    }
  });

  useEffect(() => {
    Axios.post(config.url.BOL_LIST , {carrierName, userName}, {
      headers: { "Content-type": "application/json" }
    }).then(response => {
      setBolPost(response.data);
      setBolList([response.data]);
    })
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (checked.length === 1) {
      if (currentIndex === 0) {
        newChecked.splice(currentIndex, 1);
        setChecked(newChecked);
        setBol([newChecked]);
      }
    } else {
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
      setBol([newChecked]);
    };
  };

  return (
    <div>
      <List sx={{ width: '100%', maxHeight: 230, maxWidth: '300', overflow: 'auto'}}>
        {bolPost.map((value) => {
          const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem
                key={value}
                secondaryAction={
                  <IconButton edge="end" aria-label="comments"></IconButton>
                }
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value}`} />
                </ListItemButton>
              </ListItem>
                );
            })}
        </List>
    </div>
  )
}

export default BolList