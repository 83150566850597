import React, { useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';

const Search = ({list, setSearching}) => {
  const bolList = list[0];
  
  const handleChange = (e) => {
    if (e.key === 'Enter') {
      let searching = Array.isArray(bolList) ? bolList.filter(bolList => bolList.includes(e.target.value)) : [];
      setSearching([searching]);
    }
  }

  return (
    <div>
        <TextField 
          id="poSearch"
          label="Press Enter to Search"
          size='small'
          variant="outlined"
          onKeyDown={handleChange}
          />
    </div>
  )
}

export default Search